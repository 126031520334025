<template>
  <div>
    <el-dialog
        :visible.sync="dialogVisible"
        width="30%"
        append-to-body
        :before-close="handleClose">
    <span slot="title">
      <div style="display:flex; justify-content: space-between;width: 90%">
        <div style="font-size: 18px">规则模板</div>
        <div>
          <el-button type="success" size="mini" @click="openCreateRule">新增</el-button>
        </div>
      </div>
    </span>
      <el-table
          :data="rows"
          style="width: 100%">
        <el-table-column
            prop="title"
            label="标题">
        </el-table-column>
        <el-table-column
            prop="date"
            label="操作">
          <template slot-scope="{row}">
            <el-button type="success" size="mini" @click="view(row)">查看</el-button>

            <el-button type="primary" size="mini" @click="submit(row)">使用</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex;justify-content: center">
        <paginations v-if="total>0" :total="total" :page.sync="params.current" :limit.sync="params.size"
                     @pagination="getList"
        />
      </div>
    </el-dialog>

    <create-rule-template ref="createRuleTemplate" @getList="getList"></create-rule-template>
    <view-rule-template ref="viewRuleTemplate"></view-rule-template>
  </div>

</template>
<script>
import {mixins} from "@/components/RuleTemplates/mixins";
import createRuleTemplate from  "@/components/RuleTemplates/createRuleTemplate/index.vue"
import viewRuleTemplate from "@/components/RuleTemplates/viewRuleTemplate/index.vue";

export default {
  components: {viewRuleTemplate, createRuleTemplate},
  mixins: [
      mixins
  ]
}
</script>
