export function encodeColons(url) {
    // 使用正则表达式匹配并替换
    const parts = url.match(/(https?:\/\/[^\/]+\/)(.*)/);
    if (parts) {
        const baseUrl = parts[1];
        const path = parts[2];
        const encodedPath = path.split('/').map(encodeURIComponent).join('/');
        return baseUrl + encodedPath;
    }
    return url;
}
