<template>
  <div>
    <el-dialog
        title="查看规则模板"
        :visible.sync="dialogVisible"
        width="35%"
        append-to-body
    >
    <div style="display: flex;flex-direction: column">
      <div style="margin: 20px;display: flex;">
        <div class="required" style="font-size: 15px;min-width: 100px">规则名称</div>
        <el-input disabled placeholder="请输入规则名称" size="mini" v-model="params.title" style="width: 350px;margin-left: 10px">
        </el-input>
      </div>

      <div style="margin: 20px;display: flex;">
        <div class="required" style="font-size: 15px;min-width: 100px">限制周末使用</div>
        <div style="margin-left: 10px;">
          <el-radio-group disabled v-model="params.weekendRules">
            <el-radio :label="true">限制</el-radio>
            <el-radio :label="false">不限制</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div style="margin: 20px;display: flex;">
        <div class="required" style="font-size: 15px;min-width: 100px">限时使用</div>
        <div style="margin-left: 10px;">
          <el-radio-group disabled v-model="params.isTimeRules">
            <el-radio :label="true">限时</el-radio>
            <el-radio :label="false">不限时</el-radio>
          </el-radio-group>
          <div style="background: #F7F7F7;padding: 10px;border-radius: 5px;margin-top: 5px" v-if="params.isTimeRules">
            <div style="display: flex;align-items: center;padding: 0 10px">
              <el-date-picker
                  disabled
                  v-model="params.startTimeRules"
                  type="datetime"
                  size="mini"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 175px"
                  placeholder="选择日期时间">
              </el-date-picker>
              <div style="margin: 0 10px">
                至
              </div>
              <el-date-picker
                  disabled
                  v-model="params.endTimeRules"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetime"
                  size="mini"
                  style="width: 175px"
                  placeholder="选择日期时间">
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>

      <div style="margin: 20px;display: flex;">
        <div class="required" style="font-size: 15px;min-width: 100px">使用范围</div>
        <el-input disabled placeholder="请输入套餐使用范围" size="mini" v-model="params.applicableScope" style="width: 350px;margin-left: 10px">
        </el-input>
      </div>

      <div style="margin: 20px;display: flex;">
        <div style="font-size: 15px;min-width: 100px">其他规则</div>
        <el-input disabled placeholder="请输入套餐其他规则" size="mini" v-model="params.otherRules" style="width: 350px;margin-left: 10px">
        </el-input>
      </div>



    </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </div>


    </el-dialog>


  </div>

</template>

<script>
import {mixins} from "@/components/RuleTemplates/viewRuleTemplate/mixin";

export default {
   mixins:[mixins]
}

</script>