// Vue插件
import {formatMoney, formatMoneys} from "@/utils/formatMoney";
import Clipboard from "clipboard";
import {Message} from "element-ui";
import {echarts} from "echarts";

const vuePlugin = {
    install(Vue) {
        Vue.config.productionTip = false


        Vue.filter("format", function (value, retain) {
            return formatMoney(value);
        });
        Vue.filter("formats", function (value, retain) {
            return formatMoneys(value);
        });

        // 将 Clipboard.js 挂载到 Vue 原型上
        Vue.prototype.$Clipboard = Clipboard;

        // 创建一个全局事件总线用于处理复制成功和失败的消息
        Vue.prototype.$eventBus = new Vue();

        Vue.prototype.$eventBus.$on('clipboardSuccess', () => {
            Message.success('复制成功');
        });

        Vue.prototype.$echarts = echarts;
    }
};

// 导出插件
export default vuePlugin;
