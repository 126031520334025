// user.js
import router from '@/router';
import {getLoginRoleApi, getMyPermissionApi, loginByCodeApi, userLoginApi, userLogoutApi,getMyInfoApi} from "@/api/userApi"
import {getToken, removeToken, setToken} from '@/utils/auth';
import Swal from 'sweetalert2';
import {encodeColons} from "@/utils/imgUtil";
const state = {
    token:getToken(),
    userInfo: {

    },
    roles:[]
};


const mutations = {
    SET_USER_INFO: (state, userInfo) => {
        state.userInfo = userInfo
    },
    SET_TOKEN: (state, token) => {
        setToken(token)
        state.token = token
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
};

const actions = {
    // 用户退出登录
    async loginOut({ commit }, parame) {
        return new Promise(async (resolve, reject) => {
            if (parame) {
                Swal.fire({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 2000,
                    icon: 'success',
                    title: '退出成功'
                })
                await userLogoutApi();
            }
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            resolve();
        });
    },
    // 用户登录
    userLogin({ commit ,dispatch}, parame) {
        return new Promise((resolve, reject) => {
            userLoginApi(parame).then(({data}) => {
                commit('SET_TOKEN', data)
                Swal.fire({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 2000,
                    icon: 'success',
                    title: '登录成功'
                })
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 用户手机号登录
    userTelCodeLogin({ commit ,dispatch}, parame) {
        return new Promise((resolve, reject) => {
            loginByCodeApi(parame).then(({data}) => {
                commit('SET_TOKEN', data)
                Swal.fire({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 2000,
                    icon: 'success',
                    title: '登录成功'
                })
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 获取用户详情
    async getInfo({ commit, state }) {
        let { data } = await getMyPermissionApi();
        let arr = []
        data.forEach(element => {
            arr.push(element.node)
        });
        let { data:res } = await getLoginRoleApi();
        let {data:userInfo}=await getMyInfoApi()
        userInfo.img=encodeColons(userInfo.img)
        commit("SET_USER_INFO", userInfo)
        let role=[...arr,...res];
        commit('SET_ROLES', role)
        return role;
    },
    // 重置token
    resetToken({ commit }) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            resolve()
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
