<template>
  <div id="kt_app_body" data-kt-app-header-fixed="true" data-kt-app-header-fixed-mobile="true"
       data-kt-app-sidebar-enabled="true" data-kt-app-sidebar-fixed="true" data-kt-app-sidebar-hoverable="true"
       data-kt-app-sidebar-push-toolbar="true" data-kt-app-sidebar-push-footer="true" data-kt-app-aside-enabled="true"
       data-kt-app-aside-fixed="true" :data-kt-app-sidebar-minimize="sidebarMinimize"
       class="app-default">
    <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
      <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
        <!--顶部导航-->
        <TopNav @toggleSidebar="toggleSidebar"></TopNav>
        <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
          <!--左侧导航-->
          <LeftNav></LeftNav>
          <div class="app-main flex-column flex-row-fluid" id="kt_app_main" style="margin-right: 0;background: #F9F9F9">
            <!-- 主体视图内容 -->
            <div class="d-flex flex-column flex-column-fluid">
              <div id="kt_app_content" class="app-content flex-column-fluid" style="background: #F9F9F9;padding-top: 0">
                <div id="kt_app_content_container" class=" container-fluid">
                  <div>
                    <router-view />
                  </div>
                </div>
              </div>
            </div>
            <!-- 底部WEB信息 -->
            <FooterComponent></FooterComponent>
          </div>
          <!-- 右侧导航 -->
          <RightNav></RightNav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FooterComponent from "./components/footer/index.vue"
import RightNav from "./components/right-nav/index.vue"
import TopNav from "./components/top-nav/index.vue"
import LeftNav from "./components/left-nav/index.vue"
import MainComponent from "./components/main/index.vue"

export default {
  name: "layout",
  components: {
    FooterComponent, RightNav, LeftNav, MainComponent, TopNav
  },
  data(){
    return{
      sidebarMinimize:"off"
    }
  },
  methods:{
    toggleSidebar(){
      if (this.sidebarMinimize === "on") {
        this.sidebarMinimize = "off"
      } else {
        this.sidebarMinimize="on"
      }
    }
  }
}
</script>
