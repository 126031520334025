import {queryRulePageApi} from "@/api/lxh/rule";

export const mixins= {
  data(){
    return {
        dialogVisible:false,
        ruleObj:{

        },
        params:{
            current:1,
            size:10
        },
        total:0,
        rows:[]

    }
  },
    methods:{
        handleClose(){
            this.dialogVisible=false
        },
        init(){
            this.dialogVisible=true
            this.getList()
        },
        openCreateRule(){
            this.$refs.createRuleTemplate.init()
        },
        async getList(){
            let {data}=await queryRulePageApi(this.params)
            this.rows=data.rows
            this.total = data.total
        },
        submit(ruleObj){
            this.$emit('submit',ruleObj)
            this.dialogVisible=false
        },
        view(ruleObj){
            this.$refs.viewRuleTemplate.init(ruleObj.id)
        }
    }
 }
