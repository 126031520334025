import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./permission"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import {setupClipboardDirective} from './utils/clipboard';
import '@/utils/CopyTextDirective';
import SjComponent from "@/components/SjComponent/index";
import importComponent from "@/utils/importComponent";
import vuePlugin from "@/utils/vuePlugin";
import waterfall from "vue-waterfall2";

// 在全局监听 Clipboard.js 的 success 和 error 事件，然后使用事件总线发送消息
document.addEventListener('click', (e) => {
  if (e.target.getAttribute('data-clipboard-target')) {
    Vue.prototype.$eventBus.$emit('clipboardSuccess', e);
  } else if (e.target.getAttribute('data-clipboard-text')) {
    Vue.prototype.$eventBus.$emit('clipboardError', e);
  }
});
// 引入 clipboard 模块
setupClipboardDirective();




Vue.use(SjComponent)
Vue.use(importComponent)
Vue.use(waterfall)
Vue.use(vuePlugin)
Vue.use(ElementUI);
Vue.use(VueSweetalert2);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
