import {saveRuleApi} from "@/api/lxh/rule";

export const mixins= {
    data(){
        return {
            dialogVisible:false,
            params:{
                title:'',
                weekendRules:0,
                isTimeRules:1,
                startTimeRules:'',
                endTimeRules:'',
                applicableScope:'',
                otherRules:''
            }

        }
    },
    methods:{
        handleClose(){
            this.dialogVisible=false
        },
        init(){
            this.dialogVisible=true
        },

        checkParam(){
            let result = {}
            result.code = 201
            let params = this.params;

            if(!params.title.trim()){
                result.msg = '请输入模板名称'
                return result;
            }
            if(params.isTimeRules ===1){
                if(!params.startTimeRules){
                    result.msg = '请选择开始时间'
                    return result;
                }
                if (!params.endTimeRules){
                    result.msg = '请选择结束时间'
                    return result;
                }
            }
            if(!params.applicableScope.trim()){
                result.msg = '请输入使用范围'
                return result;
            }

            result.code=200
            return result;

        },

        async submitCreate(){
            let checkResult = this.checkParam()
            if(checkResult.code!==200){
                this.$message({
                    message: checkResult.msg,
                    type: 'error'
                });
                return;
            }
            let res = await saveRuleApi(this.params)
            if(res.code === 200){
                this.$emit('getList');
                this.$message({
                    message: '添加成功！',
                    type: 'success'
                });
                this.dialogVisible=false
            }
        }
    }
}