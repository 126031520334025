import {detailRuleApi} from "@/api/lxh/rule";

export const mixins= {
    data(){
        return {
            dialogVisible:false,
            params:{
                title:'',
                weekendRules:0,
                isTimeRules:1,
                startTimeRules:'',
                endTimeRules:'',
                applicableScope:'',
                otherRules:''
            }

        }
    },
    methods:{
        handleClose(){
            this.dialogVisible=false
        },
        init(id){
            this.detailRule(id)
            this.dialogVisible=true
        },

        async detailRule(id){
            let param = {
                id
            }
            let {data} = await detailRuleApi(param)
            this.params = data
        }
    }
}