// 导入你的自定义组件


import Pagination from "@/components/Paginations/index.vue";
import TitleCard from "@/components/TitleCard/index.vue";
import ModalDialog from "@/components/ModalDialog/index.vue";
import UploadFile from "@/components/UploadFile/index.vue";
import GroupTools from "@/components/GroupTools/index.vue";
import VipPayComponent from "@/components/VipPayComponent/index.vue";
import SvgIcon from "@/components/SvgIcon/index.vue";
import MobileModalBox from "@/components/MobileModalBox/index.vue";
import CountUp from 'vue-countup-v2';
import Paginations from "@/components/Pagination/index.vue";
import MaterialComponent from "@/components/MaterialComponent/index.vue";
import RuleTemplates from "@/components/RuleTemplates/index.vue";


const importComponents = {
    install(Vue) {
        // eslint-disable-next-line vue/multi-word-component-names
        Vue.component('Pagination', Pagination);
        // eslint-disable-next-line vue/multi-word-component-names
        Vue.component('Paginations', Paginations);
        Vue.component('TitleCard', TitleCard);
        Vue.component('ModalDialog', ModalDialog);
        Vue.component('UploadFile', UploadFile);
        Vue.component('GroupTools', GroupTools)
        Vue.component('VipPayComponent', VipPayComponent)
        Vue.component('SvgIcon', SvgIcon)
        Vue.component('CountUp', CountUp)
        Vue.component('MobileModalBox', MobileModalBox)
        Vue.component("MaterialComponent", MaterialComponent)
        Vue.component("RuleTemplates", RuleTemplates);
    }
};

// 导出插件
export default importComponents;
