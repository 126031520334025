import Vue from 'vue'
import Router from 'vue-router'
import Layout from "@/layout/index.vue";
import Demo from "@/views/Demo/index.vue";
// import Layout from "@/views/MainLayout/index.vue";

Vue.use(Router)


/**
 * 常量路由
 */
export const constantRoutes = [
    {
        path: '/login',
        component: () => import('@/views/Login/index.vue'),
        hidden: true,
        name: "Login"
    },
    {
        path: '/register',
        component: () => import('@/views/Register/index.vue'),
        hidden: true,
        name: "Register"
    },
    {
        path: '/401',
        component: () => import('@/views/error-page/401.vue'),
        hidden: true
    },
    {
        path: '/',
        component: Layout,
        redirect: '/dashboard',
        children: [
            {
                path: 'dashboard',
                component: () => import('@/views/Dashboard/index'),
                name: 'Dashboard',
                meta: {title: '主页', icon: 'ki-graph-3', affix: true}
            }
        ]
    },
    {
        path: '/demo',
        component: Demo,
        redirect: '/demo',
        hidden: true,
        children: [
            {
                path: '',
                component: () => import('@/views/Demo/index.vue'),
                name: 'Dashboard',
                meta: {title: '主页', icon: 'ki-graph-3', affix: true}
            }
        ]
    }
]

/**
 * 异步路由
 */
export const asyncRoutes = [
    {
        path: '/digital-direct-store',
        component: Layout,
        redirect: '/store/info',
        name: 'Store',
        meta: {
            title: '数字直营店管理',
            icon: 'ki-home',
            roles: ["qiYe"]
        }, children: [
            {
                path: 'info',
                component: () => import('@/views/digtialDirectStore/info/index'),
                name: 'StoreInfo',
                meta: {
                    title: '数字直营店详情',
                    // roles: ["goods:manage:sjGoods:query:list"]
                }
            }, {
                path: 'info1',
                component: () => import('@/views/Dashboard/index'),
                name: 'StoreInfo',
                meta: {
                    title: '子账号管理(TODO)',
                    roles: ["qiYe"]
                }
            }, {
                path: 'furnish',
                component: () => import('@/views/digtialDirectStore/furnish/index.vue'),
                name: 'StoreFurnish',
                meta: {
                    title: '数字直营店装修',
                    roles: ["qiYe"]
                }
            }, {
                path: 'business',
                component: () => import('@/views/digtialDirectStore/business/index.vue'),
                name: 'StoreBusiness',
                meta: {
                    title: '数字直营店资质',
                    roles: ["qiYe"]
                }
            },
        ]
    },
    {
        path: '/store',
        component: Layout,
        redirect: '/store/list',
        name: 'Store',
        meta: {
            title: '门店管理',
            icon: 'ki-home',
            roles: ["goods:manage:sjGoods:query:list"]
        }, children: [
            {
                path: 'list',
                component: () => import('@/views/Store/list/index.vue'),
                name: 'StoreInfo',
                meta: {
                    title: '门店列表',
                    // roles: ["goods:manage:sjGoods:query:list"]
                }
            }, {
                path: 'info1',
                component: () => import('@/views/Dashboard/index'),
                name: 'StoreInfo',
                meta: {
                    title: '子账号管理(TODO)',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            },
            {
                path: 'tag',
                component: () => import('@/views/Store/tag/index.vue'),
                name: 'StoreTag',
                meta: {
                    title: '门店标签',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            },
            {
                path: 'furnish',
                component: () => import('@/views/Store/furnish/index.vue'),
                name: 'StoreFurnish',
                meta: {
                    title: '门店装修',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            },
            // {
            //     path: 'business',
            //     component: () => import('@/views/Store/business/index.vue'),
            //     name: 'StoreBusiness',
            //     meta: {
            //         title: '门店资质',
            //         roles: ["goods:manage:sjGoods:query:list"]
            //     }
            // },
        ]
    }, {
        path: '/goods',
        component: Layout,
        redirect: '/goods/info',
        name: 'Goods',
        meta: {
            title: '商品管理',
            icon: 'ki-purchase',
            roles: ["goods:manage:sjGoods:query:list"]
        }, children: [
            {
                path: 'info',
                component: () => import('@/views/Goods/info'),
                name: 'GoodsInfo',
                meta: {
                    title: '菜品管理',
                    roles: ["firstGradeSupplierFood"]
                }
            },
            {
                path: 'info',
                component: () => import('@/views/Goods/info'),
                name: 'GoodsInfo',
                meta: {
                    title: '服务管理',
                    roles: ["firstGradeSupplierNotFood"]
                }
            },
            // {
            //     path: 'group',
            //     component: () => import('@/views/Goods/group'),
            //     name: 'GoodsGroup',
            //     meta: {
            //         title: '分组管理',
            //         roles: ["goods:manage:sjGoods:query:list"]
            //     }
            // },
            {
                path: 'combo',
                component: () => import('@/views/Goods/combo/index.vue'),
                name: 'GoodsCombo',
                meta: {
                    title: '套餐',
                    roles: ["firstGradeSupplierFood"]
                }
            },
            {
                path: 'combo',
                component: () => import('@/views/Goods/combo/index.vue'),
                name: 'GoodsCombo',
                meta: {
                    title: '团购',
                    roles: ["firstGradeSupplierNotFood"]
                }
            },
            {
                path: 'sign',
                component: () => import('@/views/Goods/sign'),
                name: 'GoodsSign',
                meta: {
                    title: '招牌菜',
                    roles: ["firstGradeSupplierFood"]
                }
            }
        ]
    }, {
        path: '/selection',
        component: Layout,
        redirect: '/selection/index',
        name: 'Selection',
        meta: {
            title: '选品中心',
            icon: 'ki-technology-4',
            roles: ["goods:manage:sjGoods:query:list"]
        }, children: [
            {
                path: 'index',
                component: () => import('@/views/Selection/index/index.vue'),
                name: 'Selection',
                meta: {
                    title: '选品库',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }, {
                path: 'pending-order',
                component: () => import('@/views/Selection/pending-order'),
                name: 'PendingOrder',
                meta: {
                    title: '已挂单商品',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }
        ]
    }, {
        path: '/fans',
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('@/views/Fans/index'),
                name: 'Fans',
                meta: {
                    title: '粉丝管理',
                    icon: 'ki-profile-user',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }
        ]
    }, {
        path: '/content',
        component: Layout,
        redirect: '/content',
        name: 'Content',
        meta: {
            title: '文章',
            icon: 'ki-book-open',
            roles: ["goods:manage:sjGoods:query:list"]
        }, children: [
            {
                path: 'article',
                component: () => import('@/views/Content/article/index'),
                name: 'Article',
                meta: {
                    title: '文章管理',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }, {
                path: 'evaluation',
                component: () => import('@/views/Content/evaluation/articleEvaluation/index'),
                name: 'ArticleEvaluation',
                meta: {
                    title: '文章评论',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            },
        ]
    },
    {
        path: '/evaluation',
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('@/views/Content/evaluation/comboEvaluation/index'),
                name: 'ComboEvaluation',
                meta: {
                    title: '套餐评价',
                    icon: 'el-icon-notebook-2',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }
        ]
    },
    {
        path: '/private-letter',
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('@/views/PrivateLetter/index'),
                name: '',
                meta: {
                    title: '私信',
                    icon: 'el-icon-document-copy',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }
        ]
    },
    {
        path: '/order',
        component: Layout,
        redirect: '/order/offline',
        name: 'Order',
        meta: {
            title: '订单管理',
            icon: 'ki-outline ki-delivery-3',
            roles: ["goods:manage:sjGoods:query:list"]
        },
        children: [
            {
                path: 'offline',
                component: () => import('@/views/Order/offline/index.vue'),
                name: 'Order',
                meta: {
                    title: '到店服务订单',
                    icon: 'ki-outline ki-delivery-3',
                    roles: ["finance:sjCashOut:query:list:my:money"]
                }
            }, {
                path: 'on-line',
                component: () => import('@/views/Order/onLine/index.vue'),
                name: 'Order',
                meta: {
                    title: '优选商品订单',
                    icon: 'ki-outline ki-delivery-3',
                    roles: ["finance:sjCashOut:query:list:my:money"]
                }
            }
        ]
    }, {
        path: '/finance',
        component: Layout,
        redirect: '/finance/overview',
        name: 'Finance',
        meta: {
            title: '财务管理',
            icon: 'ki-outline ki-tag',
            roles: ["goods:manage:sjGoods:query:list"]
        }, children: [
            {
                path: 'overview',
                component: () => import('@/views/Finance/overview/index.vue'),
                name: 'Finance',
                meta: {
                    title: '财务总览',
                    roles: ["goods:manage:sjGoods:query:list"],
                    icon: 'ki-outline ki-tag',
                }
            }, {
                path: 'income',
                component: () => import('@/views/Finance/income/index.vue'),
                name: 'FinanceIncome',
                meta: {
                    title: '收益流水',
                    roles: ["goods:manage:sjGoods:query:list"],
                    icon: 'ki-outline ki-tag',
                }
            }, {
                path: 'withdraw',
                component: () => import('@/views/Finance/withdraw/index.vue'),
                name: 'FinanceWithdraw',
                meta: {
                    title: '提现流水',
                    roles: ["goods:manage:sjGoods:query:list"],
                    icon: 'ki-outline ki-tag',
                }
            }, {
                path: 'account',
                component: () => import('@/views/Finance/account/index.vue'),
                name: 'FinanceAccount',
                meta: {
                    title: '收款账户',
                    roles: ["goods:manage:sjGoods:query:list"],
                    icon: 'ki-outline ki-tag',
                }
            }, {
                path: 'bill',
                component: () => import('@/views/Finance/bill/index.vue'),
                name: 'FinanceBill',
                meta: {
                    title: '发票抬头',
                    icon: 'ki-outline ki-tag',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }, {
                path: 'info',
                component: () => import('@/views/Dashboard/index'),
                name: 'GoodsInfo',
                meta: {
                    title: '导出报表',
                    icon: 'ki-outline ki-tag',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }
        ]
    }, {
        path: '/activity',
        component: Layout,
        redirect: '/activity/voucher',
        name: 'Activity',
        meta: {
            title: '活动中心',
            icon: 'ki-cup',
            roles: ["goods:manage:sjGoods:query:list"]
        }, children: [
            {
                path: 'voucher',
                component: () => import('@/views/Activity/voucher/index.vue'),
                name: 'ActivityVoucher',
                meta: {
                    title: '代金券',
                    icon: 'ki-cup',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }, {
                path: 'reduce',
                component: () => import('@/views/Activity/reduce/index.vue'),
                name: 'ActivityReduce',
                meta: {
                    title: '立减券',
                    icon: 'ki-cup',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }, {
                path: 'teamwork',
                component: () => import('@/views/Activity/teamwork/index.vue'),
                name: 'ActivityTeamwork',
                meta: {
                    title: '拼团',
                    icon: 'ki-cup',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }
        ]
    }, {
        path: '/violation-and-breach-records',
        component: Layout,
        redirect: '/violation-and-breach-records/integrityScore',
        name: 'violationAndBreachRecords',
        meta: {
            title: '违规违约记录',
            icon: 'ki-information-2',
            roles: ["goods:manage:sjGoods:query:list"]
        }, children: [
            {
                path: 'integrityScore',
                component: () => import('@/views/ViolationAndBreachRecords/IntegrityScore/index'),
                name: 'IntegrityScore',
                meta: {
                    title: '诚信分',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }, {
                path: 'warning-management',
                component: () => import('@/views/ViolationAndBreachRecords/WarningManagement/index'),
                name: 'WarningManagement',
                meta: {
                    title: '预警管理',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }, {
                path: 'integrity-violations',
                component: () => import('@/views/ViolationAndBreachRecords/IntegrityViolations/index'),
                name: 'IntegrityViolations',
                meta: {
                    title: '诚信违规',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }
        ]
    }, {
        path: '/data-center',
        component: Layout,
        redirect: '/data-center/business-analysis',
        name: 'DataCenter',
        meta: {
            title: '数据中心',
            icon: 'ki-chart-pie-3',
            roles: ["goods:manage:sjGoods:query:list"]
        }, children: [
            {
                path: 'business-analysis',
                component: () => import('@/views/DataCenter/BusinessAnalysis/index'),
                name: 'BusinessAnalysis',
                meta: {
                    title: '经营分析',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }, {
                path: 'service-analysis',
                component: () => import('@/views/DataCenter/ServiceAnalysis/index'),
                name: 'ServiceAnalysis',
                meta: {
                    title: '业务分析',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }, {
                path: 'product-analysis',
                component: () => import('@/views/DataCenter/ProductAnalysis/index'),
                name: 'ProductAnalysis',
                meta: {
                    title: '商品分析',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }, {
                path: 'customer-profile',
                component: () => import('@/views/DataCenter/CustomerProfile/index'),
                name: 'CustomerProfile',
                meta: {
                    title: '客户画像',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }, {
                path: 'live-streaming-data',
                component: () => import('@/views/DataCenter/LiveStreamingData/index'),
                name: 'LiveStreamingData',
                meta: {
                    title: '直播数据',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }, {
                path: 'report-download',
                component: () => import('@/views/DataCenter/ReportDownload/index'),
                name: 'ReportDownload',
                meta: {
                    title: '报表下载',
                    roles: ["goods:manage:sjGoods:query:list"]
                }
            }
        ]
    },
    {
        path: '/Suppliers',
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('@/views/Suppliers/index2.vue'),
                name: 'Suppliers',
                meta: {
                    title: '子账号',
                    icon: 'ki-data',
                    roles: ["user:permission:create:distributor"]
                }
            }
        ]
    },
    {
        path: '/employee-account',
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('@/views/EmployeeAccount/index.vue'),
                name: 'EmployeeAccount',
                meta: {
                    title: '员工账号',
                    icon: 'el-icon-user',
                    roles: ["user:permission:create:distributor"]
                }
            }
        ]
    },
    {
        path: '/permission',
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('@/views/Permission/index.vue'),
                name: 'permission',
                meta: {
                    title: '用户权限',
                    icon: 'ki-tag',
                    roles: ["user:permission:update"]
                }
            }
        ]
    },
    {path: '*', redirect: '/404', hidden: true}
]

const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router
// const router = new Router({
//     // mode: 'history',
//     routes1
// })
//
// export default router
