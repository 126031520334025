

export function formatMoney(amount) {
    if (isNaN(Number(amount))) {
        return amount;
    }
    return '￥' +Number(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
export function formatMoneys(amount) {
    if (isNaN(Number(amount))) {
        return amount;
    }
    return Number(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}