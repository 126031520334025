import service from "@/utils/request"

/**
 * 保存规则
 * @param data
 * @returns {Promise<AxiosResponse<any>> | *}
 */
export function saveRuleApi(data) {
    return service({
        url: "/rule/saveRule",
        method: "post",
        data
    })
}

/**
 * 分页查询规则
 */
export function queryRulePageApi(params) {
    return service({
        url: "/rule/queryPage",
        method: "get",
        params
    })
}

/**
 * 删除规则
 */
export function deleteRuleApi(data) {
    return service({
        url: "/rule/deleteRule",
        method: "post",
        data
    })
}

export function detailRuleApi(params) {
    return service({
        url: "/rule/detail",
        method: "get",
        params
    })
}
