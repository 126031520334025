import {asyncRoutes, constantRoutes} from "@/router";

/**
 * 判断是否存在这个权限
 * @param roles 用户角色
 * @param route 路由列表
 * @returns {*|boolean}
 */
function hasPermission(roles, route) {
    if (route.meta && route.meta.roles) {
        return roles.some(role => route.meta.roles.includes(role))
    } else {
        return true
    }
}


/**
 * 过滤异步权限路由
 * @param routes 路由列表
 * @param roles 用户角色列表
 * @returns {*[]}
 */
export function filterAsyncRoutes(routes, roles) {
    const res = []

    routes.forEach(route => {
        const tmp = { ...route }
        if (hasPermission(roles, tmp)) {
            if (tmp.children) {
                tmp.children = filterAsyncRoutes(tmp.children, roles)
            }
            res.push(tmp)
        }
    })

    return res
}
const state = {
    routes: [],
    tempRoutes: [],
    addRoutes: [],
    routerList: [
        {
            title: "首页",
            path: ["/"],
            subTitle: "首页",
            icon: "ki-graph-3",
            children: []
        },
        {
            title: "商品",
            path: ["/Goods"],
            subTitle: "商品",
            icon: "ki-purchase",
            children: [
                {
                    title: "商品管理",
                    subTitle: "商品管理",
                    path: "/Goods",
                    icon: "ki-element-11",
                }
            ]
        },
        {
            title: "商城",
            path: [""],
            icon: "ki-shop",
            subTitle: "商城",
            children: [
                {
                    title: "商城",
                    subTitle: "商城",
                    path: "/pages/chooseGoods/chooseGoodsList.html",
                    icon: "ki-element-11",
                }
            ]
        },
        {
            title: "全国商品池",
            path: ["/chooseGoods"],
            subTitle: "全国商品池",
            icon: "ki-category",
            children: [
                {
                    title: "全国商品池",
                    subTitle: "全国商品池",
                    path: "/chooseGoods",
                    icon: "ki-element-11",
                }
            ]
        },
        {
            title: "订单",
            path: [],
            icon: "ki-Delivery-3",
            subTitle: "订单",
            children: [
                {
                    title: "订单",
                    subTitle: "订单",
                    path: "/pages/chooseGoods/chooseGoodsList.html",
                    icon: "ki-element-11",
                }
            ]
        },
        {
            title: "财务",
            path: [],
            icon: "ki-tag",
            subTitle: "财务",
            children: [
                {
                    title: "财务",
                    subTitle: "财务",
                    path: "/pages/chooseGoods/chooseGoodsList.html",
                    icon: "ki-element-11",
                }
            ]
        },
        {
            title: "供应商",
            subTitle: "供应商",
            path: [],
            icon: "ki-badge",
            children: [
                {
                    title: "全国商品池",
                    subTitle: "全国商品池",
                    path: "/pages/chooseGoods/chooseGoodsList.html",
                    icon: "ki-element-11",
                }
            ]
        }
    ]
}

const mutations = {
    SET_ROUTES: (state, routes) => {
        state.addRoutes = routes
        state.routes = constantRoutes.concat(routes)
    }
}

const actions = {
    generateRoutes({commit}, roles) {
        return new Promise(resolve => {
            let accessedRoutes
            if (roles.includes('admin')) {
                accessedRoutes = asyncRoutes || []
            } else {
                accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
            }
            commit('SET_ROUTES', accessedRoutes)
            resolve(accessedRoutes)
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
