<template>
  <div id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false"
       class="app-sidebar-menu-primary menu menu-column menu-rounded menu-sub-indention menu-state-bullet-primary px-3 mb-5">
    <div data-kt-menu-trigger="click" class="menu-item here show menu-accordion">
      <template v-for="(item,index) in permission_routes" v-if="!item.hidden">
         <!--存在子菜单-->
         <span class="menu-link" v-if="item.meta" @click="clickArrow(index)" :class="hoverType(item)">
            <span class="menu-icon">
              <i class="ki-outline  fs-2" :class="item.meta.icon"></i>
            </span>
            <span class="menu-title"  style="font-weight: 400">{{ item.meta.title }}</span>
            <span class="menu-arrow" :class="{'rotate-180': index !== activeIndex}"></span>
        </span>
         <!--只存在一级菜单-->
         <router-link :to="item.path" class="menu-link" v-if="item.children[0] && !item.meta"  :class="hoverType(item)">
            <span class="menu-icon">
              <i class="ki-outline  fs-2" :class="item.children[0].meta.icon"></i>
            </span>
           <span class="menu-title" style="font-weight: 400">{{ item.children[0].meta.title }}</span>
         </router-link>
         <!--<span class="menu-link " v-if="item.children[0]" @click="jumpPage(item)">-->
         <!--    -->
         <!--</span>-->
         <!--判断是否存在子菜单-->
         <!--v-if="item.meta"-->
         <div class="menu-item here active menu-accordion" v-if="item.meta" :class="{
           'hiddenBox': index !== activeIndex
         }">
           <div class="menu-item" v-for="(ite,i) in item.children" :key="i" style="margin-left: 20px;">
             <router-link class="menu-link" style="padding: 6px 13px" :to="item.path+'/'+ite.path" active-class="active">
                  <span class="menu-bullet">
                    <span class="bullet bullet-dot"></span>
                  </span>
                 <span class="menu-title" style="font-weight: 400">{{ ite.meta.title }}</span>
             </router-link>
           </div>
         </div>
       </template>
    </div>


  </div>
</template>
<script>

import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters([
      'permission_routes'
    ]),

  },
  data() {
      return{
        activeIndex:0
      }
    },
  methods:{
    hoverType(item) {
      if (this.$route.path==='/dashboard' && item.path==='/'){
          return 'active'
      }
      return this.$route.matched[0].path===item.path?'active':''
    },
    jumpPage(item){
      this.$router.push(item.path)
    },
    clickArrow(i){
      if (this.activeIndex===i){
        this.activeIndex = '';
      }else{
        this.activeIndex = i;
      }
    }
  }

}
</script>
<style>
.hiddenBox{
  display: none;
}
.rotate-180 {
  transform: rotate(180deg);
}
</style>
