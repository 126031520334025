<template>
  <div>
    <h2>Draggable Components</h2>
    <draggable v-model="components" class="draggable-container" :group="{ name: 'components', pull: 'clone', put: false }">
      <div v-for="component in components" :key="component.id" class="draggable-item">
        {{ component.name }}
      </div>
    </draggable>

    <h2>Drop Zone</h2>
    <draggable v-model="dropZone" class="draggable-container" style="flex-direction: column" :group="{ name: 'components', pull: false, put: true }">
      <div v-for="component in dropZone" :key="component.id" class="draggable-item">
        {{ component.name }}
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable
  },
  data() {
    return {
      components: [
        { id: 1, name: 'Header' },
        { id: 2, name: 'Footer' },
        { id: 3, name: 'Button' },
        { id: 4, name: 'Text' }
      ],
      dropZone: []
    };
  }
};
</script>

<style>
.draggable-container {
  display: flex;
  flex-wrap: wrap;
  //margin-bottom: 20px;
}

.draggable-item {
  width: 100px;
  height: 100px;
  background-color: lightblue;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
}
</style>
