import Vue from 'vue'
import Vuex from 'vuex'
import getters from "./getters"

Vue.use(Vuex)




import userModules from "./modules/user"
import permissionModules from "./modules/permission"
import webModules from "./modules/web"
import goodsModules from "./modules/goods"
import diaLogModal from "@/store/modules/diaLogModal";

const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

export default new Vuex.Store({
  getters,
  modules: {
    user:userModules,
    permission:permissionModules,
    web:webModules,
    goods:goodsModules,
    diaLog:diaLogModal,
    ...modules
  }
})
